import { navOptions } from "../config";
import { Link } from "react-router-dom";

export const renderNavOptions = () => {
  return navOptions.map(({ name, href }, i) => {
    const isActive = window.location.pathname === href;
    return (
      <Link to={href} key={i}>
        <div className={`option ${isActive ? "option--active" : ""}`}>
          {name}
        </div>
      </Link>
    );
  });
};
