import React from "react";
import { envelopeIcon, phoneIcon } from "../../constants/Icons";
import Icon from "../UI/Icon";
import "./QuickContact.scss";
import { mail, phone } from "./../../config";

function QuickContact() {
  return (
    <div className="quickContact">
      <a href={`mailto:${mail}`} className="quickContact__item">
        <Icon icon={envelopeIcon} size={22} />
        <div className="quickContact__details">{mail}</div>
      </a>
      <a href={`tel:${phone}`} className="quickContact__item">
        <Icon icon={phoneIcon} size={18} />
        <div className="quickContact__details">{phone}</div>
      </a>
    </div>
  );
}

export default QuickContact;
