import React from "react";

import Button from "../UI/Button";
import OfferTile from "./OfferTile";
import "./Offer.scss";
import { Link } from "react-router-dom";
import {
  balanceScaleIcon,
  fileDollarIcon,
  helmetIcon,
  userCogItem,
} from "../../constants/Icons";

function Offer() {
  return (
    <div className="offer">
      <div className="sectionTitle">
        <div className="sectionTitleText">Oferta</div>
      </div>
      <div className="offer__tiles">
        <OfferTile name="Księgowość" icon={fileDollarIcon}>
          Elastyczna oferta dostosowana jest zarówno do małych firm, jak i
          dużych spółek, a także stowarzyszeń, fundacji i osób fizycznych.
          Oferujemy kompleksową obsługę w zakresie:
          <ul>
            <li>prowadzenia ryczałtu ewidencjonowanego</li>
            <li>prowadzenia podatkowej księgi przychodów i rozchodów</li>
            <li>prowadzenie ksiąg handlowych</li>
            <li>prowadzenia rejestrów VAT</li>
            <li>sporządzania deklaracji dla Urzędów Skarbowych, ZUS, GUS</li>
            <li>sporządzania sprawozdań finansowych</li>
            <li>
              wyprowadzania zaległości z tytułu rozliczeń z Urzędem Skarbowym i
              ZUS
            </li>
            <li>
              sporządzenia wniosków o zwrot VAT za materiały budowlane (VZM)
            </li>
          </ul>
          Ceny usług księgowych uzależnione są od indywidualnej wyceny dla
          każdego Klienta w drodze negocjacji. W szczególności zależą od:
          <ul>
            <li>formy opodatkowania</li>
            <li>formy prawnej podmiotu</li>
            <li>rodzaju prowadzonej działalności (branża)</li>
            <li>liczby dokumentów i zapisów księgowych</li>
            <li>liczby zatrudnionych pracowników</li>
            <li>
              stopnia złożoności rozliczeń podatkowych (transakcje WNT,
              export/import itp.)
            </li>
          </ul>
        </OfferTile>
        <OfferTile name="Kadry i Płace" icon={userCogItem}>
          Profesjonalna realizacja procesów związanych z naliczaniem wynagrodzeń
          oraz administrowaniem dokumentacją pracowniczą. Oferujemy kompleksową
          obsługę w zakresie:
          <ul>
            <li>Oferujemy kompleksową obsługę w zakresie:</li>
            <li>
              zakładania i prowadzenia teczek osobowych dla pracowników Klienta,
            </li>
            <li>
              prowadzenia kartotek Klienta w programie kadrowo- płacowym oraz
              kartotek dla każdego pracownika oraz zleceniobiorcy,
            </li>
            <li>kompletowania dokumentacji pracowniczej,</li>
            <li>
              sporządzania umów o pracę oraz innych dokumentów związanych z
              nawiązaniem, trwaniem i rozwiązaniem stosunku pracy,
            </li>
            <li>
              sporządzania wypowiedzeń umów o pracę, oświadczeń woli dotyczących
              rozwiązania umów bez zachowania okresu wypowiedzenia i innych
              dokumentów wymaganych przepisami prawa,
            </li>
            <li>sporządzania dokumentów dotyczących kar i nagród,</li>
            sporządzania zaświadczeń o zatrudnieniu i zarobkach,
            <li>
              bieżącego wyjaśniania Klientowi pojawiających się problemów
              związanych z Prawem Pracy,
            </li>
            <li>
              naliczania list płac dla pracowników oraz list umów
              cywilnoprawnych,
            </li>
            <li>
              rozliczania zwolnień lekarskich, naliczanie zasiłków, prowadzenie
              dokumentacji zasiłkowej,
            </li>
            <li>
              obliczania wszelkich składników wynagrodzeń występujących u
              Klienta,
            </li>
            <li>
              przygotowywania dokumentacji związanej z realizacją zajęć
              komorniczych,
            </li>
            <li>
              dokonywania zgłoszeń, zmian i wyrejestrowań pracowników i
              zleceniobiorców w ZUS,
            </li>
            <li>
              sporządzania i przesyłanie do ZUS odpowiednich deklaracji
              rozliczeniowych w zakresie wymaganym obowiązującymi przepisami,
            </li>
            <li>
              sporządzania miesięcznych i rocznych deklaracji PFRON, doradztwo w
              zakresie możliwości obniżenia wpłat na PFRON,
            </li>
            <li>
              realizacji przelewów bankowych dla pracowników, ZUS, podatku
              dochodowego od osób fizycznych oraz PFRON,
            </li>
            <li>
              sporządzania comiesięcznych raportów według indywidualnych życzeń
              Klienta, np. zestawień urlopów wypoczynkowych, urlopów na żądanie
              oraz dni wolnych z tytułu opieki nad dzieckiem (art.188 KP),
              zestawień absencji chorobowych, zestawień godzin nadliczbowych,
            </li>
            <li>sporządzania sprawozdań GUS,</li>
            <li>korekt „30-krotności”,</li>
            <li>
              sporządzania zaświadczeń do celów emerytalno-rentowych RP-7,
            </li>
            <li>pomocy i wsparciu podczas kontroli PIP, ZUS oraz US,</li>
            <li>
              sporządzaniu regulaminów pracy, wynagrodzeń, Zakładowego Funduszu
              Świadczeń Socjalnych, merytoryczna pomoc w rozwiązywaniu problemów
              z zakresu Prawa Pracy.
            </li>
          </ul>
        </OfferTile>

        <OfferTile name="Kompleksowa usługa BHP i P.POŻ." icon={helmetIcon}>
          Oferujemy kompleksową obsługę w zakresie:
          <ul>
            <li>kompleksowej obsługi w zakresie BHP i PPOŻ,</li>
            <li>
              szkoleń pracowników i pracodawców w zakresie BHP (wstępne i
              okresowe) oraz ochrony PPOŻ,
            </li>
            <li>prowadzenia audytów,</li>
            <li>sporządzania dokumentacji ryzyka zawodowego,</li>
            <li>monitorowania terminów badań lekarskich,</li>
            <li>
              sporządzania pełnej dokumentacji wypadkowej- wypadki w pracy oraz
              w drodze do pracy i z pracy,
            </li>
            <li>
              pomocy i wsparcia merytorycznego podczas kontroli PIP i PIS oraz
              pomoc w realizacji nakazów,
            </li>
            <li>opracowywaniu instrukcji BHP</li>
          </ul>
        </OfferTile>

        <OfferTile name="Kancelaria Prawna" icon={balanceScaleIcon}>
          <h4 style={{ marginBottom: 0 }}>Michał Uciński</h4>
          <p>
            Wieloletnie, praktyczne doświadczenie w obszarze szeroko rozumianego
            prawa cywilnego i gospodarczego zdobywałem m.in. jako prawnik
            wewnętrzny dużej grupy kapitałowej oraz współpracownik renomowanych
            warszawskich kancelarii prawnych.
          </p>
          <p>
            Prowadzenie sporów, szczególnie na etapie sądowym, to sztuka
            wymagająca wszechstronnej znajomości przepisów i orzecznictwa, ale
            także przywiązywania wagi do szczegółów, które nierzadko okazują się
            kluczowe dla wyniku danej sprawy. Specjalizuję się w opracowaniu i
            realizacji strategii procesowych w sprawach cywilnych i
            gospodarczych.
          </p>
          <p>
            Jestem adwokatem wpisanym na listę Okręgowej Rady Adwokackiej w
            Warszawie
          </p>
          <p>
            <a href="https://ucinski.com/">www.ucinski.com</a>
          </p>
        </OfferTile>
      </div>
      <div className="offer__callToActionWrapper">
        <div className="offer__callToActionText">Chcesz poznać szczegóły?</div>
        <div className="offer__callToAction">
          <Link to="/wycena">
            <Button fontSize="28px">Skontaktuj się z nami</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Offer;
