import React from "react";
import AboutSection from "../landing/AboutSection";
import "./About.scss";
import CrewCard from "./CrewCard";

function About() {
  return (
    <div className="about">
      <div className="borderBar"></div>
      <AboutSection />
      <div className="about__content">
        <div className="about__crew">
          <div className="abour__row">
            <CrewCard
              img="/assets/crew/port5.jpg"
              name="Michał Uciński"
              title="Adwokat"
            />
            <CrewCard
              img="/assets/crew/port3.jpg"
              name="Iwona Cyglincka-Cęcelek"
              title="Prezes spółki <br/>
              Główna księgowa"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
