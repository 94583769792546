import "./App.scss";
import NavBar from "./components/navigation/NavBar";

import MainSection from "./components/landing/MainSection";
import Offer from "./components/landing/Offer";
import AboutSection from "./components/landing/AboutSection";
import Footer from "./components/navigation/Footer";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Contact from "./components/contact/Contact";
import Pricing from "./components/pricing/Pricing";
import About from "./components/about/About";
import PrivacyPolicy from "./components/privacy-policy/PrivacyPolicy";

function App() {
  return (
    <Router>
      <div className="app">
        <NavBar />

        <Switch>
          <Route path="/o-nas">
            <About />
          </Route>
          <Route path="/wycena">
            <Pricing />
          </Route>
          <Route path="/kontakt">
            <Contact />
          </Route>

          <Route path="/polityka-prywatnosci">
            <PrivacyPolicy />
          </Route>

          <Route path="/">
            <>
              <MainSection />
              <Offer />
              <AboutSection />
            </>
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
