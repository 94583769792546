import React from "react";

function Icon({ icon, size = 20 }) {
  return (
    <div className="icon" style={{ height: `${size}px` }}>
      {icon}
    </div>
  );
}

export default Icon;
