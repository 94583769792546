import React from "react";
import { renderNavOptions } from "../../helpers/navOptions";
import "./NavBar.scss";
import QuickContact from "./QuickContact";
import { Link, useLocation } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu";

function NavBar() {
  useLocation(); //To trigger re-render after route change

  return (
    <div className="navbar">
      <QuickContact />
      <div className="header">
        <div className="mobileNav">
          <HamburgerMenu />
        </div>

        <div className="desktopNav">
          <Link to="/">
            <div className="logo">
              <img src="/assets/goldlogo.jpg" alt="logo" />
            </div>
          </Link>
          <nav>{renderNavOptions()}</nav>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
