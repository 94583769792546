import React from "react";
import {
  calendarIcon,
  graduationIcon,
  padlockIcon,
  perfectionIcon,
} from "../../constants/Icons";
import Icon from "./../UI/Icon";
import "./AboutSection.scss";

function AboutSection() {
  return (
    <div className="aboutSection">
      <div className="sectionTitle" style={{ height: "7vh" }}>
        <div className="sectionTitleText">O nas</div>
      </div>
      <div className="aboutSection__content">
        <p>
          ICC Finanse specjalizuje się w kompleksowej obsłudze kadrowo –
          płacowej oraz finansowo – księgowej podmiotów gospodarczych
          działających na terenie Polski. Naszymi Klientami są firmy z kapitałem
          polskim i zagranicznym, o różnorodnych profilach działalności:
          handlowej, usługowej, produkcyjnej, transportowej. Świadczymy usługi
          zarówno dla spółek prawa handlowego, spółek cywilnych, jak również
          przedsiębiorców działających jako osoby fizyczne prowadzące
          działalność gospodarczą.
        </p>
        <p>
          Nasz zespół tworzą profesjonaliści z długoletnim, popartym sukcesami
          doświadczeniem zawodowym. Wszyscy pracowali na stanowiskach
          menedżerskich w obszarze kadr i płac oraz finansów i księgowości w
          dużych, międzynarodowych organizacjach o profilu produkcyjnym i
          usługowym. W oparciu o tak bogatą praktykę zapewniamy naszym Klientom
          usługi na najwyższym poziomie.
        </p>
      </div>
      <div className="aboutSection__photo">
        <img src="/assets/narrowbackground.jpg" alt="" />
      </div>
      <div className="aboutSection__attributes">
        <div className="aboutSection__attribute">
          <div className="aboutSection__attributeIcon">
            <Icon icon={calendarIcon} size="47" />
          </div>
          <div className="aboutSection__attributeName">Elastyczność</div>
        </div>

        <div className="aboutSection__attribute">
          <div className="aboutSection__attributeIcon">
            <Icon icon={graduationIcon} size="47" />
          </div>
          <div className="aboutSection__attributeName">Doświadczenie</div>
        </div>

        <div className="aboutSection__attribute">
          <div className="aboutSection__attributeIcon">
            <Icon icon={perfectionIcon} size="47" />
          </div>
          <div className="aboutSection__attributeName">Rzetelność</div>
        </div>

        <div className="aboutSection__attribute">
          <div className="aboutSection__attributeIcon">
            <Icon icon={padlockIcon} size="47" />
          </div>
          <div className="aboutSection__attributeName">Bezpieczeństwo</div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
