import React from "react";
import { mail } from "../../config";
import "./PrivacyPolicy.scss";

function PrivacyPolicy() {
  return (
    <div className="privacyPolicy">
      <div className="borderBar"></div>
      <div className="privacyPolicy__content">
        <p>
          <strong>
            Polityka prywatności opisuje zasady przetwarzania przez nas
            informacji na Twój temat, w tym danych osobowych oraz ciasteczek,
            czyli tzw. cookies.
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong> Informacje ogólne</strong>
        </p>
        <p>
          1. Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod adresem
          ul:Napoleońska 1 99-400 Łowicz
        </p>
        <p>
          2. Operatorem serwisu oraz Administratorem danych osobowych jest: ICC
          Finanse Biuro Rachunkowe Łowicz. ul: Napoleońska 1 99-400 Łowicz
        </p>
        <p>3. Operatorem serwisu jest ICC Finanse Biuro Rachunkowe Łowicz</p>
        <p>4. Adres kontaktowy poczty elektronicznej operatora: {mail}</p>
        <p>
          5. Operator jest Administratorem Twoich danych osobowych w odniesieniu
          do danych podanych dobrowolnie w Serwisie.
        </p>
        <p>6. Serwis wykorzystuje dane osobowe w następujących celach:</p>
        <ul>
          <li>Prowadzenie newslettera</li>
          <li>Obsługa zapytań przez formularz</li>
          <li>Realizacja zamówionych usług</li>
          <li>Prezentacja oferty lub informacji</li>
        </ul>
        <p>
          7. Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i
          ich zachowaniu w następujący sposób:
        </p>
        <ul>
          <li>
            Poprzez dobrowolnie wprowadzone w formularzach dane, które zostają
            wprowadzone do systemów Operatora.
          </li>
          <li>
            Poprzez zapisywanie w urządzeniach końcowych plików cookie (tzw.
            „ciasteczka”).
          </li>
        </ul>
        <p>
          <strong>
            {" "}
            Wybrane metody ochrony danych stosowane przez Operatora
          </strong>
        </p>
        <p>
          1. Operator okresowo zmienia swoje hasła administracyjne.
          <br />
          2. W celu ochrony danych Operator regularnie wykonuje kopie
          bezpieczeństwa.
          <br />
          3. Istotnym elementem ochrony danych jest regularna aktualizacja
          wszelkiego oprogramowania, wykorzystywanego przez Operatora do
          przetwarzania danych osobowych, co w szczególności oznacza regularne
          aktualizacje komponentów programistycznych.
        </p>
        <p>
          <strong> Hosting</strong>
        </p>
        <p>
          1. Serwis jest hostowany (technicznie utrzymywany) na serwera
          operatora: home.pl
        </p>
        <p>
          <strong>
            {" "}
            Twoje prawa i dodatkowe informacje o sposobie wykorzystania danych
          </strong>
        </p>
        <p>
          1. W niektórych sytuacjach Administrator ma prawo przekazywać Twoje
          dane osobowe innym odbiorcom, jeśli będzie to niezbędne do wykonania
          zawartej z Tobą umowy lub do zrealizowania obowiązków ciążących na
          Administratorze. Dotyczy to takich grup odbiorców:
        </p>
        <ul>
          <li>firma hostingowa na zasadzie powierzenia</li>
          <li>
            upoważnieni pracownicy i współpracownicy, którzy korzystają z danych
            w celu realizacji celu działania strony
          </li>
          <li>firmy, świadczące usługi marketingu na rzecz Administratora</li>
        </ul>
        <p>
          2. Twoje dane osobowe przetwarzane przez Administratora nie dłużej,
          niż jest to konieczne do wykonania związanych z nimi czynności
          określonych osobnymi przepisami (np. o prowadzeniu rachunkowości). W
          odniesieniu do danych marketingowych dane nie będą przetwarzane dłużej
          niż przez 3 lata.
        </p>
        <p>3. Przysługuje Ci prawo żądania od Administratora:</p>
        <ul>
          <li>dostępu do danych osobowych Ciebie dotyczących,</li>
          <li>ich sprostowania,</li>
          <li>usunięcia,</li>
          <li>ograniczenia przetwarzania,</li>
          <li>oraz przenoszenia danych.</li>
        </ul>
        <p>
          4. Przysługuje Ci prawo do złożenia sprzeciwu w zakresie przetwarzania
          wskazanego w pkt 3.3 c) wobec przetwarzania danych osobowych w celu
          wykonania prawnie uzasadnionych interesów realizowanych przez
          Administratora, w tym profilowania, przy czym prawo sprzeciwu nie
          będzie mogło być wykonane w przypadku istnienia ważnych prawnie
          uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Ciebie
          interesów, praw i wolności, w szczególności ustalenia, dochodzenia lub
          obrony roszczeń.
          <br />
          5. Na działania Administratora przysługuje skarga do Prezesa Urzędu
          Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.
          <br />
          6. Podanie danych osobowych jest dobrowolne, lecz niezbędne do obsługi
          Serwisu.
          <br />
          7. W stosunku do Ciebie mogą być podejmowane czynności polegające na
          zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu
          świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia
          przez Administratora marketingu bezpośredniego.
          <br />
          8.Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu
          przepisów o ochronie danych osobowych. Oznacza to, że nie przesyłamy
          ich poza teren Unii Europejskiej.
        </p>
        <p>
          <strong> Informacje w formularzach</strong>
        </p>
        <p>
          1. Serwis zbiera informacje podane dobrowolnie przez użytkownika, w
          tym dane osobowe, o ile zostaną one podane.
          <br />
          2. Serwis może zapisać informacje o parametrach połączenia (oznaczenie
          czasu, adres IP).
          <br />
          3. Serwis, w niektórych wypadkach, może zapisać informację ułatwiającą
          powiązanie danych w formularzu z adresem e-mail użytkownika
          wypełniającego formularz. W takim wypadku adres e-mail użytkownika
          pojawia się wewnątrz adresu url strony zawierającej formularz.
          <br />
          4. Dane podane w formularzu są przetwarzane w celu wynikającym z
          funkcji konkretnego formularza, np. w celu dokonania procesu obsługi
          zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług itp.
          Każdorazowo kontekst i opis formularza w czytelny sposób informuje, do
          czego on służy.
        </p>
        <p>
          <strong> Logi Administratora</strong>
        </p>
        <p>
          1. Informacje zachowaniu użytkowników w serwisie mogą podlegać
          logowaniu. Dane te są wykorzystywane w celu administrowania serwisem.
        </p>
        <p>
          <strong> Istotne techniki marketingowe</strong>
        </p>
        <p>
          1. Operator stosuje analizę statystyczną ruchu na stronie, poprzez
          Google Analytics (Google Inc. z siedzibą w USA). Operator nie
          przekazuje do operatora tej usługi danych osobowych, a jedynie
          zanonimizowane informacje. Usługa bazuje na wykorzystaniu ciasteczek w
          urządzeniu końcowym użytkownika. W zakresie informacji o preferencjach
          użytkownika gromadzonych przez sieć reklamową Google użytkownik może
          przeglądać i edytować informacje wynikające z plików cookies przy
          pomocy narzędzia: https://www.google.com/ads/preferences/
          <br />
          2. Operator stosuje techniki remarketingowe, pozwalające na
          dopasowanie przekazów reklamowych do zachowania użytkownika na
          stronie, co może dawać złudzenie, że dane osobowe użytkownika są
          wykorzystywane do jego śledzenia, jednak w praktyce nie dochodzi do
          przekazania żadnych danych osobowych od Operatora do operatorom
          reklam. Technologicznym warunkiem takich działań jest włączona obsługa
          plików cookie.
          <br />
          3. Operator stosuje korzysta z piksela Facebooka. Ta technologia
          powoduje, że serwis Facebook (Facebook Inc. z siedzibą w USA) wie, że
          dana osoba w nim zarejestrowana korzysta z Serwisu. Bazuje w tym
          wypadku na danych, wobec których sam jest administratorem, Operator
          nie przekazuje od siebie żadnych dodatkowych danych osobowych
          serwisowi Facebook. Usługa bazuje na wykorzystaniu ciasteczek w
          urządzeniu końcowym użytkownika.
          <br />
          4. Operator stosuje rozwiązanie badające zachowanie użytkowników
          poprzez tworzenie map ciepła oraz nagrywanie zachowania na stronie. Te
          informacje są anonimizowane zanim zostaną przesłane do operatora
          usługi tak, że nie wie on jakiej osoby fizycznej one dotyczą. W
          szczególności nagrywaniu nie podlegają wpisywane hasła oraz inne dane
          osobowe.
          <br />
          5. Operator stosuje rozwiązanie automatyzujące działanie Serwisu w
          odniesieniu do użytkowników, np. mogące przesłać maila do użytkownika
          po odwiedzeniu konkretnej podstrony, o ile wyraził on zgodę na
          otrzymywanie korespondencji handlowej od Operatora.
        </p>
        <p>
          <strong> Informacja o plikach cookies</strong>
        </p>
        <p>
          1. Serwis korzysta z plików cookies.
          <br />
          2. Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
          szczególności pliki tekstowe, które przechowywane są w urządzeniu
          końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron
          internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony
          internetowej, z której pochodzą, czas przechowywania ich na urządzeniu
          końcowym oraz unikalny numer.
          <br />
          3. Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika
          Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator
          Serwisu.
          <br />
          4. Pliki cookies wykorzystywane są w następujących celach:
        </p>
        <ul>
          <li>
            utrzymanie sesji użytkownika Serwisu (po zalogowaniu), dzięki której
            użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać
            loginu i hasła;
          </li>
          <li>
            realizacji celów określonych powyżej w części “Istotne techniki
            marketingowe”;
          </li>
        </ul>
        <p>
          5. W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików
          cookies: „sesyjne” (session cookies) oraz „stałe” (persistent
          cookies). Cookies „sesyjne” są plikami tymczasowymi, które
          przechowywane są w urządzeniu końcowym Użytkownika do czasu
          wylogowania, opuszczenia strony internetowej lub wyłączenia
          oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies
          przechowywane są w urządzeniu końcowym Użytkownika przez czas
          określony w parametrach plików cookies lub do czasu ich usunięcia
          przez Użytkownika.
          <br />
          6. Oprogramowanie do przeglądania stron internetowych (przeglądarka
          internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików
          cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą
          dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa
          umożliwia usunięcie plików cookies. Możliwe jest także automatyczne
          blokowanie plików cookies Szczegółowe informacje na ten temat zawiera
          pomoc lub dokumentacja przeglądarki internetowej.
          <br />
          7. Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre
          funkcjonalności dostępne na stronach internetowych Serwisu.
          <br />
          8. Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
          Serwisu wykorzystywane mogą być również przez współpracujące z
          operatorem Serwisu podmioty, w szczególności dotyczy to firm: Google
          (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w
          USA), Twitter (Twitter Inc. z siedzibą w USA).
        </p>
        <p>
          <strong>
            {" "}
            Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?
          </strong>
        </p>
        <p>
          1. Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić
          ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików
          cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa,
          utrzymania preferencji użytkownika może utrudnić, a w skrajnych
          przypadkach może uniemożliwić korzystanie ze stron www
        </p>
        <p>
          2. W celu zarządzania ustawienia cookies wybierz z listy poniżej
          przeglądarkę internetową, której używasz i postępuj zgodnie z
          instrukcjami:
        </p>
        <ul>
          <li>
            <u>
              <a href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history">
                Edge
              </a>
            </u>
          </li>
          <li>
            <u>
              <a href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                Internet Explorer
              </a>
            </u>
          </li>
          <li>
            <u>
              <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                Chrome
              </a>
            </u>
          </li>
          <li>
            <u>
              <a href="http://support.apple.com/kb/PH5042">Safari</a>
            </u>
          </li>
          <li>
            <u>
              <a href="http://support.mozilla.org/pl/kb/Włączanie%20i%20wyłączanie%20obsługi%20ciasteczek">
                Firefox
              </a>
            </u>
          </li>
          <li>
            <u>
              <a href="http://help.opera.com/Windows/12.10/pl/cookies.html">
                Opera
              </a>
            </u>
          </li>
        </ul>
        <p>Urządzenia mobilne:</p>
        <ul>
          <li>
            <u>
              <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                Android
              </a>
            </u>
          </li>
          <li>
            <u>
              <a href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL">
                Safari (iOS)
              </a>
            </u>
          </li>
          <li>
            <u>
              <a href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings">
                Windows Phone
              </a>
            </u>
          </li>
        </ul>
        <p>
          Niniejszy wzór polityki został wygenerowany bezpłatnie, w celach
          informacyjnych, w oparciu o naszą wiedzę, branżowe praktyki i przepisy
          prawa obowiązujące na dzień 2018-08-14. Zalecamy sprawdzenie wzoru
          polityki przed użyciem jej na stronie. Wzór opiera się na najczęściej
          występujących na stronach internetowych sytuacjach, ale może nie
          odzwierciedlać pełnej i dokładnej specyfiki Twojej strony www.
          Przeczytaj uważnie wygenerowany dokument i w razie potrzeb dostosuj go
          do Twojej sytuacji lub zasięgnij porady prawnej. Nie bierzemy
          odpowiedzianości za skutki posługiwania się tym dokumentem, ponieważ
          tylko Ty masz wpłw na to, czy wszystkie informacje w nim zawarte są
          zgodne z prawdą. Zwróć także uwagę, że Polityka Prywatności, nawet
          najlepsza, jest tylko jednym z ekmentów Twojej troski o dane osobowe i
          prywatność użytkownika na stronie www.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
