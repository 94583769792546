import React, { useState } from "react";
import Icon from "../UI/Icon";
import "./OfferTile.scss";

function OfferTile({ icon, name, children }) {
  const [wrapped, setWrapped] = useState(true);
  return (
    <div
      className={`offerTile offerTile ${
        wrapped && "offerTile offerTile--wrapped"
      }`}
    >
      <div className="oferTile__title">
        <div className="oferTile__icon">
          <Icon icon={icon} size="30" />
        </div>
        <div className="oferTile__name">{name}</div>
      </div>
      <div className="oferTile__content">{children}</div>

      {wrapped && (
        <div
          className="oferTile__unwrap"
          onClick={() => {
            setWrapped(false);
          }}
        >
          Więcej
        </div>
      )}
    </div>
  );
}

export default OfferTile;
