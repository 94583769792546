import React from "react";
import { addressFirstLine, addressSecondLine, phone } from "../../config";
import { phoneIcon } from "../../constants/Icons";
import Icon from "../UI/Icon";
import Button from "../UI/Button";
import "./MainSection.scss";
import { Link } from "react-router-dom";

function MainSection() {
  return (
    <div className="mainSection">
      <img src="/assets/background3.jpg" alt="bacground" />
      <div className="mainSection__contentWrapper">
        <div className="mainSection__title">
          <h1>Biuro rachunkowe</h1>
        </div>
        <div className="mainSection__details">
          <div className="address">
            {addressFirstLine}
            <br />
            {addressSecondLine}
          </div>
          <div>
            <a href={`tel:${phone}`} className="phoneContact">
              <Icon icon={phoneIcon} size={26} />
              <div className="phoneContact__details">{phone}</div>
            </a>
          </div>
        </div>
        <div className="mainSection__quickPreviewWrapper">
          <div className="mainSection__quickPreview">
            <h3>
              Potrzebujesz szczegółowej i szybkiej wyceny usług księgowych?
            </h3>
            Możesz w tym celu skorzystać z naszego formularza
          </div>
          <Link to="/wycena">
            <Button>Wypełnij formularz kontaktowy</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MainSection;
