import React, { useState } from "react";
import Icon from "./../UI/Icon";
import { hamburgerMenuIcon } from "./../../constants/Icons";

import "./HamburgerMenu.scss";
import { renderNavOptions } from "../../helpers/navOptions";

function HamburgerMenu() {
  const [active, setActive] = useState(false);

  return (
    <div className="hamburgerMenu">
      <div className="hamburgerMenu__mainNav">
        <div className="hamburgerMenu__logo">
          <img src="/assets/goldlogo.jpg" alt="logo" />
        </div>
        <div
          className="hamburgerMenu__hamburgerIcon"
          onClick={() => {
            setActive(!active);
          }}
        >
          <Icon icon={hamburgerMenuIcon} size="36" />
        </div>
      </div>
      {active && (
        <div className="hamburgerMenu__nav">
          <nav>{renderNavOptions()}</nav>
        </div>
      )}
    </div>
  );
}

export default HamburgerMenu;
