export const businessName = "ICC Finanse";
export const phone = "695 498 436";
export const mail = "bok@iccfinanse.pl";
export const addressFirstLine = "ul. Napoleońska 1,";
export const addressSecondLine = "99-400 Łowicz";
export const navOptions = [
  {
    name: "HOME",
    href: "/",
  },
  {
    name: "O NAS",
    href: "/o-nas",
  },
  {
    name: "WYCENA",
    href: "/wycena",
  },
  {
    name: "KONTAKT",
    href: "/kontakt",
  },
];
