import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Pricing.scss";
function Pricing() {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [sent, setSent] = useState(false);

  var serializeObject = function (form) {
    var obj = {};

    Array.prototype.slice.call(form.elements).forEach(function (field) {
      if (
        !field.name ||
        field.disabled ||
        ["file", "reset", "submit", "button"].indexOf(field.type) > -1
      )
        return;
      if (field.type === "select-multiple") {
        var options = [];

        Array.prototype.slice.call(field.options).forEach(function (option) {
          if (!option.selected) return;
          options.push(option.value);
        });

        if (options.length) {
          obj[field.name] = options;
        }

        return;
      }

      if (["checkbox", "radio"].indexOf(field.type) > -1 && !field.checked)
        return;

      obj[field.name] = field.value;
    });

    return obj;
  };

  const submitForm = (e) => {
    e.preventDefault();

    const formObject = serializeObject(e.target);
    const formData = new FormData();

    for (var key in formObject) {
      formData.append(key, formObject[key]);
    }
    setSent(true);

    axios({
      method: "post",
      url: "/contact.php",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        alert(
          "Gotowe! \nSkontaktujemy się z tobą tak szybko jak to tylko możliwe!"
        );
        window.location.replace("/");
      })
      .catch(function (response) {
        setSent(false);
        alert("Wystąpił problem, prosimy spróbować ponownie później.");
      });
  };
  return (
    <div className="pricing">
      <div className="borderBar"></div>

      <div className="pricing__content">
        <div className="sectionTitle" style={{ height: "7vh" }}>
          <div className="sectionTitleText">Wycena</div>
        </div>
        <h2>Wypełnij formularz, a dostosujemy ofertę do Twoich potrzeb.</h2>
        <h3>
          Pola oznaczone <span className="req">*</span> są wymagane
        </h3>
        <div className="pricing__form">
          <form onSubmit={submitForm}>
            {/* Imie i nazwisko */}
            <div className="formField">
              <div className="formField__name">
                Imię i nazwisko <span className="req">*</span>:
              </div>
              <input type="text" name="name" required />
            </div>

            {/* Email */}
            <div className="formField">
              <div className="formField__name">
                Adres e-mail <span className="req">*</span>:
              </div>
              <input type="email" name="email" required />
            </div>

            {/* Tel. */}
            <div className="formField">
              <div className="formField__name">Numer telefonu:</div>
              <input type="tel" name="tel" />
            </div>

            {/* Oferowane uslugi. */}
            <div className="formField">
              <div className="formField__name">
                Jakie usługi Cię interesują:
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="checkbox" name="uslugi_ksiegowosc" />
                  Prowadzenie księgowości
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="checkbox" name="uslugi_kadry" />
                  Kadry i Płace
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="checkbox" name="uslugi_bhp" />
                  BHP i P.POŻ.
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="checkbox" name="uslugi_prawnik" />
                  Kancelaria Prawna
                </label>
              </div>
              <div className="formField__additionalText">
                Inne:
                <input type="text" name="uslugi_inne" />
              </div>
            </div>

            {/* Forma prawna. */}
            <div className="formField">
              <div className="formField__name">Określ formę prawną firmy:</div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="radio" name="formaprawna" value="jdg" />
                  Osoba fizyczna (działalność gospodarcza)
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="radio" name="formaprawna" value="Sp. z o.o." />
                  Sp. z o.o.
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input
                    type="radio"
                    name="formaprawna"
                    value="Spółka osobowa (cywilna, komandytowa, jawna)"
                  />
                  Spółka osobowa (cywilna, komandytowa, jawna)
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input
                    type="radio"
                    name="formaprawna"
                    value="Spółka akcyjna"
                  />
                  Spółka akcyjna
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input
                    type="radio"
                    name="formaprawna"
                    value="Stowarzyszenie"
                  />
                  Stowarzyszenie
                </label>
              </div>
            </div>

            {/* Ile osob zatrudnia firma. */}
            <div className="formField">
              <div className="formField__name">
                Ile osób zatrudnia Twoja firma:
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="radio" name="zatrudnienie" value="0" />
                  Prowadzę jednoosobową działalność
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="radio" name="zatrudnienie" value="1-5" />
                  Do 5
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="radio" name="zatrudnienie" value="6-10" />
                  6-10
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="radio" name="zatrudnienie" value="11-20" />
                  11-20
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="radio" name="zatrudnienie" value="21-50" />
                  21-50
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="radio" name="zatrudnienie" value="51-100" />
                  51-100
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="radio" name="zatrudnienie" value="101-300" />
                  101-300
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="radio" name="zatrudnienie" value="300<" />
                  Powyżej 300
                </label>
              </div>
            </div>

            {/* Ilosc faktur. */}
            <div className="formField">
              <div className="formField__name">
                Ile średnio faktur miesięcznie generuje firma
                <span className="req">*</span>:
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input
                    type="radio"
                    name="iloscfaktur"
                    value="0-20"
                    required
                  />
                  0-20
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input
                    type="radio"
                    name="iloscfaktur"
                    value="21-50"
                    required
                  />
                  21-50
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input
                    type="radio"
                    name="iloscfaktur"
                    value="51-100"
                    required
                  />
                  51-100
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input
                    type="radio"
                    name="iloscfaktur"
                    value="100<"
                    required
                  />
                  Powyżej 100
                </label>
              </div>
            </div>

            {/* Platnik VAT. */}
            <div className="formField">
              <div className="formField__name">
                Czy firma jest płatnikiem VAT
                <span className="req">*</span>:
              </div>

              <div className="formField__selectableWrapper">
                <label>
                  <input type="radio" name="statusVat" value="tak" required />
                  Tak
                </label>
              </div>
              <div className="formField__selectableWrapper">
                <label>
                  <input type="radio" name="statusVat" value="nie" required />
                  Nie
                </label>
              </div>
            </div>

            {/* Uwagi. */}
            <div className="formField">
              <div className="formField__name">Wiadomość:</div>

              <div className="formField__selectableWrapper">
                <textarea name="uwagi" id="uwagi"></textarea>
              </div>
            </div>

            <div className="formField">
              <label style={{ fontSize: 15 }}>
                <input
                  type="checkbox"
                  name="terms"
                  id="terms"
                  checked={acceptedTerms}
                  onChange={(e) => setAcceptedTerms(e.target.checked)}
                  required
                />{" "}
                &nbsp;
                <span className="req">*</span>
                Wyrażam zgodę na przetwarzanie moich danych osobowych na
                zasadach określonych w{" "}
                <Link to="/polityka-prywatnosci">regulaminie.</Link>
              </label>
            </div>

            <div
              className={`submit ${
                !acceptedTerms || sent ? "submit--disabled" : ""
              }`}
            >
              <input type="submit" value="Wyślij" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
