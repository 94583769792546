import React from "react";
import { Link } from "react-router-dom";
import { addressFirstLine, addressSecondLine, mail, phone } from "../../config";
import { renderNavOptions } from "../../helpers/navOptions";
import "./Footer.scss";

function Footer() {
  return (
    <footer>
      <div className="footer__content">
        <div className="footer__leftPart">
          <div className="footer__logo">
            <img src="/assets/darklogo.jpg" alt="Logo" />
          </div>
          <div className="footer__contact">
            <div className="footer__address">
              {addressFirstLine}
              <br />
              {addressSecondLine}
            </div>
            <div className="footer__phoneAndMail">
              tel: {phone}
              <br />
              e-mail: {mail}
            </div>
          </div>
        </div>
        <div className="footer__rightPart">
          <div className="footer__nav">{renderNavOptions()}</div>
          <div className="author">
            <Link to="/polityka-prywatnosci">Polityka prywatności</Link> <br />
            <a href="https://dziuda.it/">Projekt i wykonanie: dziuda.it</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
