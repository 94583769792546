import React from "react";
import "./Button.scss";

function Button({ children, fontSize = "22px" }) {
  return (
    <button className="styledButton" style={{ fontSize }}>
      {children}
    </button>
  );
}

export default Button;
