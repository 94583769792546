import React from "react";
import "./CrewCard.scss";

function CrewCard({ name, title, img }) {
  return (
    <div className="crewCard">
      <div className="crewCard__photoWrapper">
        <div className="crewCard__photo">
          <img src={img} alt="Team" />
        </div>
      </div>
      <div className="crewCard__content">
        <div className="crewCard__details">
          <div className="crewCard__name">{name}</div>
          <div
            className="crewCard__positionName"
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default CrewCard;
