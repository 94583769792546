import Button from "./../UI/Button";
import React from "react";
import { Link } from "react-router-dom";
import {
  addressFirstLine,
  addressSecondLine,
  businessName,
  mail,
  phone,
} from "../../config";
import "./Contact.scss";
function Contact() {
  return (
    <div className="contact">
      <div className="borderBar"></div>
      <div className="contact__content">
        <div className="sectionTitle" style={{ height: "7vh" }}>
          <div className="sectionTitleText">Kontakt</div>
        </div>

        <div className="contact__options">
          <div className="contact__contactOption">
            <span className="b_name">{businessName}</span>
            <span className="b_address">{addressFirstLine}</span>
            <span className="b_address">{addressSecondLine}</span>
            <span className="b_tel">tel: {phone}</span>
            <span className="b_email"> e-mail: {mail}</span>
          </div>

          <div className="contact__contactOption">
            <div className="contact__cta">
              <div className="contact__ctaText">
                W celu wyceny usług księgowych skorzystaj z naszego formularza:
              </div>
              <Link to="/wycena">
                <Button>Wypełnij formularz kontaktowy</Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="contact__map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9802.768899883797!2d19.9529364!3d52.1035327!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb61dc38a5f149244!2sBiuro%20rachunkowe%20%C5%81owicz%20-%20ICC%20CHR!5e0!3m2!1spl!2spl!4v1638120078394!5m2!1spl!2spl"
            width="100%"
            height="450"
            title="map"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;
